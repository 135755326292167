import { Button, ModalConsumer, ResponsiveEmbed, TopicResourceTag, TopicResourceType } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import TopicResourceModal from './TopicResourceModal';
import { isAdobeSparkEmbeddable } from '../../helper/urlHelper';
import BasicPlayerWrapper, { links as basicPlayerWrapperStyles } from '../Playback/BasicPlayerWrapper';
import HandleIds from '~/constants/handleIds';
import { handleError } from '~/helper/imgOnError';
import topicResourceStyles from '~/styles/components/Topics/TopicResource.css';

import type { ShowModalType } from '@flipgrid/flipkit';
import type { SyntheticEvent } from 'react';
import type { Topic, Focus, TopicTemplate, RouteTyping } from 'types';

export const links = () => [...basicPlayerWrapperStyles(), { rel: 'stylesheet', href: topicResourceStyles }];

type Props =
{
  topic: Topic;
  type?: 'Topic';
} |
{
  topic: TopicTemplate;
  type: 'TopicTemplate';
};

const TopicResource = ({ topic, type }: Props) => {
  const matches = useMatches();
  const ua = (matches.find((m) => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const [isMediaPaused, setIsMediaPaused] = useState(false);
  const { t } = useTranslation();
  const getResourceImage = (focus: Focus) => {
    let image = focus.image_url + '?size=large';
    if (focus.type === TopicResourceType.Giphy) {
      const removeDownsized = focus.image_url.replace('giphy-downsized-medium', 'giphy');
      const removePastedGiphyCode = removeDownsized.replace('200_d', 'giphy');
      image = removePastedGiphyCode.replace('gif', 'mp4');
    } else if (focus.type === TopicResourceType.Emoji) {
      image = focus.image_url;
    } else if (focus.type === TopicResourceType.ExternalLink && focus.image_url && focus.image_url.includes('adobe')) {
      image = focus.image_url;
    }

    return image;
  };

  const getStartTime = (time: string) => {
    let startTime = Number(time);
    const match = time.match(/(\d+h)?(\d+m)?(\d+s)?/);
    if (match) {
      const hours = match[1] && Number(match[1].split('h')[0]) * 3600 || 0;
      const minutes = match[2] && Number(match[2].split('m')[0]) * 60 || 0;
      const seconds = match[3] && Number(match[3].split('s')[0]) || 0;
      startTime = hours + +minutes + +seconds;
    }
    return startTime;
  };

  const getVideoID = (url: string) => {
    const regEx = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regEx);
    const startTime = url.split('?t=')[1] || url.split('&t=')[1];
    const id = match && match[2].length === 11 ? match[2] : url;

    return startTime ? id + '?start=' + getStartTime(startTime) : id;
  };

  const handleGiphyClick = () => {
    const giphyVideo = document.getElementById('giphyVideo');
    if (giphyVideo) {
      if (isMediaPaused) {
        setIsMediaPaused(false);
        // @ts-ignore
        const playPromise = giphyVideo.play();
        if (playPromise !== undefined) playPromise.catch(() => {});
      } else {
        setIsMediaPaused(true);
        // @ts-ignore
        giphyVideo.pause();
      }
    }
  };

  const handleResourceClick = (showModal: ShowModalType) => {
    // We display the topic card in the recorder. This handles opening the resource in a new tab instead
    // of opening another modal on top of the recorder
    showModal(TopicResourceModal, { topic });
  };

  const { focus } = topic;

  if (!focus) return null;
  // For No Resource or Emoji
  // Emoji display lives in TopicCard
  if (focus.type === TopicResourceType.Other || focus.type === TopicResourceType.Emoji) return null;
  // Partner Resource
  // For Adobe Spark videos, we hit their oembed endpoint and get a custom thumbnail.
  // We also want to show their video in our TopicResourceModal as opposed to opening in
  // a new link, so we let this case fall through if the resource is an embeddable spark.
  if (focus.type === TopicResourceType.ExternalLink && !isAdobeSparkEmbeddable(focus)) {
    return (
      <a
        className="topicResource__button"
        data-testid="topicResource__link__topic"
        aria-label={t('topicResource.topicLink')}
        href={focus.resource}
        target="_blank"
        rel="noreferrer noopener">

        <img src={getResourceImage(focus)} alt="" onError={(e: SyntheticEvent<HTMLImageElement>) => handleError(e)} />
        <TopicResourceTag focus={focus} playBtn viewLabel={t('common.view')} />
      </a>);

  }
  // Giphy
  if (focus.type === TopicResourceType.Giphy) {
    return (
      <ModalConsumer>
        {({ showModal }) =>
        <div className="topicResource__button">
            <button
            type="button"
            className={
            'topicResource__button' + (
            ua?.iOS ? '' : ' topicResource__giphy' + (isMediaPaused ? ' -play' : ' -pause'))}

            aria-label={
            isMediaPaused ?
            t('topicResource.clickToPlay', { title: topic.title }) :
            t('topicResource.clickToPause', { title: topic.title })}

            onClick={
            ua?.iOS ?
            () => {
              handleResourceClick(showModal);
            } :
            handleGiphyClick}

            data-testid="topicResource__button__topicResource">

              {ua?.iOS ?
            <img src={focus.image_url} alt={t('topicResource.topicResource')} /> :

            <video
              className="topicResource__giphyVideo"
              src={getResourceImage(focus)}
              id="giphyVideo"
              autoPlay={!isMediaPaused}
              muted
              loop />}


            </button>

            <Button
            theme="clear"
            className="topicResource__giphyButton"
            aria-label={t('topicResource.giphyTag')}
            data-testid="topicResource__button__giphy"
            // @ts-ignore
            icon={<TopicResourceTag focus={focus} playBtn viewLabel={t('common.view')} />}
            onClick={handleGiphyClick} />

          </div>}

      </ModalConsumer>);

  }
  // Youtube / Vimeo
  if (focus.type === TopicResourceType.ExternalVideo) {
    const isYoutube = focus.resource.indexOf('youtu') > -1;
    const videoURL = isYoutube ?
    'https://www.youtube-nocookie.com/embed/' + getVideoID(focus.resource) :
    focus.resource;
    return (
      <div className="topicResourceViewer">
        <ResponsiveEmbed className="topicResourceViewer__responsiveEmbed">
          <iframe title={t('topicResourceDisplay.topicResourceVideo')} src={videoURL} allowFullScreen />
        </ResponsiveEmbed>
      </div>);

  }
  // Video
  if (focus.type === TopicResourceType.Video && type !== 'TopicTemplate') {
    // TODO: Support TopicTemplate
    return <BasicPlayerWrapper entity={{ item: focus, type: 'TopicFocus', topic }} />;
  }
  // All other resources
  return (
    <ModalConsumer>
      {({ showModal }) =>
      <button
        type="button"
        className="topicResource__button"
        aria-label={t('topicResource.topicMediaClickToEnlarge', { title: topic.title })}
        onClick={() => {
          handleResourceClick(showModal);
        }}
        data-testid="topicResource__button__topicResource">

          <img src={getResourceImage(focus)} alt={t('topicResource.topicResource')} />
          <TopicResourceTag
          darkMode
          // @ts-ignore
          focus={focus}
          viewLabel={t('common.view')} />

        </button>}

    </ModalConsumer>);

};

export default TopicResource;