import { Modal, TopicResourceType } from '@flipgrid/flipkit';
import { useMatches } from '@remix-run/react';
import { useTranslation } from 'react-i18next';

import HandleIds from '~/constants/handleIds';

import type { OnRequestCloseType } from '@flipgrid/flipkit';
import type { RouteTyping, Topic, TopicTemplate } from 'types';

type Props = {
  onRequestClose: OnRequestCloseType;
  topic: Topic | TopicTemplate;
};

const TopicResourceModal = ({ topic, onRequestClose }: Props) => {
  const { t } = useTranslation();
  const matches = useMatches();
  const ua = (matches.find(m => m.handle?.id === HandleIds.Root)?.data as RouteTyping<'Root'>)?.ua;
  const { focus } = topic;

  if (!focus) return null;

  return (
    <Modal
      className={`topicResourceModal${
        focus.type === TopicResourceType.Giphy || TopicResourceType.Image ? '' : ' -wide'
      }`}
      aria-label={t('topicResourceModal.enlargedTopicMedia')}
      onClose={onRequestClose}
      closeClassName={`topicResourceModal__closeButton${ua?.mobile ? ' -outsideModal' : ''}`}
      theme="clear"
    >
      {(focus.type === TopicResourceType.Giphy || focus.type === TopicResourceType.Image) && (
        <img src={focus.image_url} alt="" />
      )}
    </Modal>
  );
};

export default TopicResourceModal;
